import React from 'react';

const Skills = () => {
  return (
    <>
      <div class="container py-20">
        <h2 class="text-grayAsparagus text-center text-3xl mb-5">Skills</h2>
        <div class="flex justify-center">
          <div class="p-8 w-full lg:w-8/12">
            <div>
              <div class="bg-ebony my-4 p-2 w-full">
                <div class="text-snow">PHP</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-full">
                <div class="text-snow">HTML</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-full">
                <div class="text-snow">CSS</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-11/12">
                <div class="text-snow">JavaScript</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-11/12">
                <div class="text-snow">React</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-10/12">
                <div class="text-snow">NodeJS</div>
              </div>
              <div class="bg-ebony my-4 p-2 w-8/12">
                <div class="text-snow">TypeScript</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
