import React from 'react';
import Layout from '../components/Layout/layout';
import SEO from '../components/SEO/seo';
import Header from '../components/Header/header';
import Skills from '../components/Skills/skills';
import Roles from '../components/Roles/roles';
import Footer from '../components/Footer/footer';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Home" />
      <Header />
      <hr class="my-4 border-mummysTomb" />
      <Skills />
      <hr class="my-4 border-mummysTomb" />
      <Roles />
      <hr class="my-4 border-mummysTomb" />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
