import React from 'react';
import orangebus from './orangebus.png';
import homegroup from './homegroup.png';

const Roles = () => {
  const roles = [
    {
      title: 'Lead developer',
      company: 'Home Group',
      location: 'Newcastle Upon Tyne',
      logo: homegroup,
      description:
        'Home Group is a housing association, social enterprise, charity and one of the UK’s largest providers of high quality housing and integrated housing, health and social care.',
      date: 'Sept 20 - Present',
    },
    {
      title: 'Lead developer',
      company: 'Orange Bus',
      location: 'Newcastle Upon Tyne',
      logo: orangebus,
      description:
        'Orange Bus is a product and service design agency.  The creativity, agility and technical expertise combine to deliver exceptional digital interactions that work for everyone.',
      date: 'Dec 18 - Sept 20',
    },
  ];

  return (
    <>
      <div class="container py-20">
        <h2 class="text-grayAsparagus text-center text-3xl mb-5">Roles</h2>
        <div class="flex-none md:flex justify-center">
          {roles.map((role) => (
            <div class="md:flex rounded w-full md:w-8/12 p-8">
              <div class="md:h-auto md:w-48 flex-none rounded-t lg:rounded-t-none lg:rounded-l self-center overflow-hidden">
                <img class="rounded-full" src={role.logo} alt={role.company} />
              </div>
              <div class=" p-4 flex flex-col justify-between leading-normal">
                <div class="mb-8">
                  <div class="text-ebony font-bold text-xl mb-2">
                    {role.title}
                  </div>
                  <p class="text-gray-700 text-base">{role.description}</p>
                </div>
                <div class="flex items-center">
                  <div class="text-sm">
                    <p class="text-gray-900">{role.company}</p>
                    <p class="text-gray-600">{role.location}</p>
                    <p class="text-gray-600">{role.date}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Roles;
