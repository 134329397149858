import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faTwitter,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <>
      <footer>
        <div class="flex justify-center">
          <a
            href="https://www.linkedin.com/in/darren-murphy-4660464a/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="text-snow text-center bg-grayAsparagus px-4 py-2 m-2">
              <FontAwesomeIcon icon={faLinkedinIn} />
            </div>
          </a>
          <a
            href="https://twitter.com/dmurphy331"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="text-snow text-center bg-ebony px-4 py-2 m-2">
              <FontAwesomeIcon icon={faTwitter} />
            </div>
          </a>
          <a
            href="https://www.instagram.com/dmurphy331/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div class="text-snow text-center bg-mummysTomb px-4 py-2 m-2">
              <FontAwesomeIcon icon={faInstagram} />
            </div>
          </a>
        </div>
      </footer>
    </>
  );
};

export default Footer;
