import React from 'react';
import logo from './photo.jpg';

const Header = () => {
  return (
    <>
      <div class="container">
        <div class="flex flex-col md:flex-row justify-center h-screen">
          <div class="flex self-center mb-6">
            <img class="rounded-full" src={logo} alt="Darren Murphy" />
          </div>
          <div class="text-center md:w-6/12 self-center">
            <h1 class="text-5xl">
              I'm <span class="text-ebony">Darren</span>.
              <br />A full-stack web developer.
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
